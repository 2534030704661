<template>
  <div class="analysis">
    <analysis-header :needSave="false" />
    <div class="search-gran">
      <div class="primary-title bk-f" style="padding: 5px 0">
        <span style="margin: 0 50px 0 10px"> 院系未打卡数量统计</span><el-date-picker v-model="dates" type="daterange"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-button style="margin-left: 10px" @click="searchChartsItem" type="primary">查询</el-button>
      </div>

      <div class="d-f jc-sb bk-f">
        <div class="w-49 h-400">
          <Bar ref="bar" />
        </div>
        <div class="w-49 h-400">
          <Pie ref="pie" />
        </div>
      </div>
    </div>
    <div class="analysis-bottom d-f jc-sb">
      <div class="w-49 h-100_ bk-f">
        <Curve />
      </div>
      <div class="w-49 h-100_ bk-f">
        <Tables />
      </div>
    </div>
  </div>
</template>

<script>
import AnalysisHeader from "./clockWarnHeader.vue";
import Bar from "./bar.vue";
import Pie from "./pie.vue";
import Curve from "./curve.vue";
import Tables from "./table.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("clock");
import dayjs from "dayjs";
export default {
  name: "analysis",
  components: {
    AnalysisHeader,
    Bar,
    Pie,
    Curve,
    Tables,
  },
  data() {
    return {
      dates: [],
      analysis: {
        labels: [
          "计算机科学",
          "土木工程",
          "经济管理学院",
          "体育学院",
          "文学",
          "市政房产",
          "化学",
        ],
        values: [10, 52, 200, 334, 390, 330, 220],
      },
    };
  },
  methods: {
    ...mapActions(["getUnClockNumber"]),
    searchChartsItem() {
      this.getUnClockNumber({
        startTime: this.dates[0] || "",
        endTime: this.dates[1] || "",
      });
    },
    showCharts(list) {
      let labels = [];
      let datas = [];
      list.forEach((item) => {
        labels.push(item.academyName);
        datas.push(item.peopleNum);
      });
      this.$refs.bar.initCharts(labels, datas);
      this.$refs.pie.initCharts(labels, datas);
    },
  },
  mounted() {
    // this.random();
    console.log(this.daterange);
    const subDay = dayjs().subtract(6, "month");
    this.dates =
      this.daterange && this.daterange.length
        ? [...this.daterange]
        : [
          (subDay.unix() > dayjs(`${new Date().getFullYear()}-01-01`).unix() ? subDay : dayjs(`${new Date().getFullYear()}-01-01`)).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ];
    this.showCharts(this.unclockList);
    !(this.unclockList && this.unclockList.length) && this.searchChartsItem();
  },
  computed: {
    ...mapState(["daterange", "unclockList"]),
  },
  watch: {
    unclockList(val) {
      this.showCharts(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis {
  background: #f0f0f0;
  padding: 10px 30px;

  .w-49 {
    width: calc(50% - 7px);
  }

  .h-400 {
    height: 390px;
  }

  .search-gran {
    padding: 10px 0px;
  }

  .analysis-bottom {
    height: calc(100vh - 565px);
    padding: 0 0px 5px 0px;
  }
}
</style>

