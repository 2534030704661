import { render, staticRenderFns } from "./curve.vue?vue&type=template&id=54ba48aa&scoped=true"
import script from "./curve.vue?vue&type=script&lang=js"
export * from "./curve.vue?vue&type=script&lang=js"
import style0 from "./curve.vue?vue&type=style&index=0&id=54ba48aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ba48aa",
  null
  
)

export default component.exports