<template>
  <div class="w-100_ h-100_">
    <div class="curve-title primary-title">未打卡前100条信息</div>
    <div class="curve-condition d-f ai-c jc-sb">
      <el-date-picker v-model="dates" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
        value-format="yyyy-MM-dd">
      </el-date-picker>
      <el-select style="width: 150px" v-model="academyId" @change="orgChange" placeholder="请选择" clearable>
        <el-option v-for="item in academyList" :key="item.id" :label="item.orgName" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="departmentId" style="width: 150px" placeholder="请选择" clearable>
        <el-option v-for="item in systemsList" :key="item.id" :label="item.orgName" :value="item.id">
        </el-option>
      </el-select>
      <span>
        <el-button @click="getTableList" type="primary">搜索</el-button>
        <el-button @click="exportTop100Table" v-if="!isMM" type="primary" style="margin: 5px">导出列表</el-button>
      </span>
    </div>
    <div ref="tableBox" style="height: calc(100% - 75px)">
      <el-table :height="tableHeight" :data="top100List" style="width: 100%">
        <el-table-column label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column prop="name" width="100" label="姓名">
        </el-table-column>
        <el-table-column prop="unClockInNum" label="未打卡次数">
        </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="identityNo" label="身份证号码">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("clock");
import dayjs from "dayjs";
const subDay = dayjs().subtract(6, "month");
export default {
  name: "curve",
  data() {
    return {
      dates: [
        (subDay.unix() > dayjs(`${new Date().getFullYear()}-01-01`).unix() ? subDay : dayjs(`${new Date().getFullYear()}-01-01`)).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      departmentId: null,
      academyId: null,
      tableHeight: 300,
      systemsList: [],
      academyList: [],
      charts: null,
    };
  },
  methods: {
    ...mapActions(["getAcademyList", "getTop100Unclock", "getTop100Excel"]),
    exportTop100Table() {
      const { departmentId, academyId } = this;
      let params = {
        academyId,
        departmentId,
      };
      !params.academyId && delete params.academyId;
      !params.departmentId && delete params.departmentId;
      if (this.dates && this.dates.length == 2) {
        params = {
          ...params,
          startAndEndTime: this.dates.join("至"),
          startTime: this.dates[0],
          endTime: this.dates[1],
        };
      }
      console.log();
      this.getTop100Excel(params);
    },
    orgChange(id) {
      this.departmentId = "";
      this.systemsList = [];
      this.departmentId = "";
      this.academyList.forEach((item) => {
        if (item.id == id) {
          this.systemsList = item.subOrganizationList || [];
          this.departmentId = this.systemsList[0]?.id ?? "";
        }
      });
    },
    getTableList() {
      const { departmentId, academyId } = this;
      let params = {
        academyId,
        departmentId,
      };
      !params.academyId && delete params.academyId;
      !params.departmentId && delete params.departmentId;
      if (this.dates && this.dates.length == 2) {
        params = {
          ...params,
          startTime: this.dates[0],
          endTime: this.dates[1],
        };
      }
      this.getTop100Unclock(params);
    },
  },
  mounted() {
    this.tableHeight = this.$refs.tableBox.clientHeight;
    this.getAcademyList().then((res) => {
      this.academyList = res;
    });
    this.getTableList();
  },
  computed: {
    ...mapState(["top100List"]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.curve-title {
  padding: 5px 10px;
}

.curve-condition {
  padding: 5px 8px;
}

.curve-charts {
  height: calc(100% - 75px);
}
</style>
