<template>
  <div class="w-100_ h-100_">
    <div class="curve-title primary-title">院系未打卡曲线变化</div>
    <div class="curve-condition d-f ai-c jc-sb">
      <el-date-picker
        v-model="times"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-select
        style="width: 200px"
        v-model="systems"
        multiple
        collapse-tags
        placeholder="请选择"
      >
        <el-option
          v-for="item in systemsList"
          :key="item.id"
          :label="item.orgName"
          :value="item.id"
        >
          <div
            :title="item.orgName"
            style="
              width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: inline-block;
            "
          >
            {{ item.orgName }}
          </div>
        </el-option>
      </el-select>
      <el-select v-model="statistics" style="width: 150px" placeholder="请选择">
        <el-option
          v-for="item in statisticsList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getCurvData" style="margin-right: 10px"
        >搜索</el-button
      >
    </div>
    <div class="curve-charts" ref="charts"></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("clock");
import * as echarts from "echarts";
import dayjs from "dayjs";
const subDay = dayjs().subtract(6, "month");
export default {
  name: "curve",
  data() {
    return {
      times: [
      (subDay.unix() > dayjs(`${new Date().getFullYear()}-01-01`).unix() ? subDay : dayjs(`${new Date().getFullYear()}-01-01`)).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      systems: "",
      statistics: 2,
      systemsList: [],
      statisticsList: [
        { id: 1, name: "合并统计" },
        { id: 2, name: "不合并统计" },
      ],
      charts: null,
      mockChartsData: [],
    };
  },
  methods: {
    ...mapActions(["getAcademyList", "getEveryDayUnclockNumber"]),
    getCurvData() {
      let params = {};
      if (this.times && this.times.length == 2) {
        params = {
          startTime: this.times?.[0]?dayjs(this.times[0]).format('YYYY-MM-DD'):"",
          endTime: this.times?.[1]?dayjs(this.times[1]).format('YYYY-MM-DD'):"",
        };
        this.getEveryDayUnclockNumber(params);
      }
    },
    initCharts(names, values) {
      if (this.charts) {
        const option = {
          // title: {
          //   text: "未打卡人数",
          //   textStyle: {
          //     fontWeight: 300,
          //     fontSize: 16,
          //   },
          // },
          legend: {
            data: values.map((it) => it.name),
            left: "left",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "13%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: names,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                rotate: 45,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: values.map((it) => ({
            name: it.name,
            type: "line",
            smooth: true,
            barWidth: "60%",
            data: it.value,
          })),
           dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 60
        },
        {
          start: 0,
          end: 10
        }
      ],
        };
        this.charts.clear();
        this.charts.setOption(option);
      }
    },
    showLineChart(list) {
      const uniqList = {}; //  去重
      const params = {};
      list
        .filter((it) =>
          this.systems && this.systems.length
            ? this.systems.includes(it.academyId)
            : it
        )
        .forEach((item) => {
          if (params[item.academyName]) {
            params[item.academyName][item.unClockDay] = item.peopleNum;
          } else {
            params[item.academyName] = { [item.unClockDay]: item.peopleNum };
          }
          if (!uniqList[item.unClockDay]) {
            uniqList[item.unClockDay] = true;
          }
        });
      const timesList = Object.keys(uniqList).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      console.log(params);
      const data =
        this.statistics == 2
          ? Object.keys(params).map((it) => ({
              name: it,
              value: timesList.map((y) => params[it][y] || 0),
            }))
          : [
              {
                name: "合计",
                value: timesList.map((y) =>
                  Object.keys(params).reduce(
                    (c, n) => c + +(params[n][y] || 0),
                    0
                  )
                ),
              },
            ];
      this.initCharts(timesList, data);
    },
  },
  mounted() {
    this.charts = echarts.init(this.$refs.charts);
    this.initCharts(
      ["一月", "二月", "三月", "四月", "五月", "六月", "七月"],
      this.mockChartsData
    );

    this.getAcademyList().then((res) => {
      this.systemsList = res;
    });
    this.getCurvData();
  },
  watch: {
    systems(val) {
      this.showLineChart(this.unclockEveryDayList);
    },
    statistics(val) {
      this.showLineChart(this.unclockEveryDayList);
    },
    unclockEveryDayList(list) {
      this.showLineChart(list);
    },
  },
  computed: {
    ...mapState(["unclockEveryDayList"]),
  },
};
</script>

<style lang="scss" scoped>
.curve-title {
  padding: 5px 10px;
}
.curve-condition {
  padding: 5px 8px;
}
.curve-charts {
  height: calc(100% - 75px);
}
</style>
