<template>
  <div class="w-100_ h-100_ bk-f" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "echarts-pie",
  data() {
    return {
      charts: null,
    };
  },
  props: {},
  mounted() {
    this.charts = echarts.init(this.$refs.echarts);
    // this.initCharts();
  },
  methods: {
    initCharts(names, values) {
      const data = names.map((it, idex) => ({ value: values[idex], name: it }));

      if (this.charts) {
        const option = {
          title: {
            text: "占比分析",
            left: "center",
            textStyle: {
              fontWeight: 300,
              fontSize: 16,
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                normal: {
                  formatter: "{b}({d}%)",
                  textStyle: {
                    fontWeight: "normal",
                    fontSize: 15,
                  },
                },
              },
            },
          ],
        };
        this.charts.setOption(option);
      }
    },
  },
};
</script>

<style>
</style>