<template>
  <div class="w-100_ h-100_ bk-f" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "echarts-bars",
  data() {
    return {
      charts: null,
    };
  },
  props: {},
  mounted() {
    this.charts = echarts.init(this.$refs.echarts);
    // this.initCharts();
  },
  methods: {
    initCharts(names, values) {
      if (this.charts) {
        const option = {
          title: {
            text: "未打卡人数",
            textStyle: {
              fontWeight: 300,
              fontSize: 16,
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: names,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                rotate: 45,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "未打卡人数",
              type: "bar",
              barWidth: "60%",
              data: values,
            },
          ],
        };
        this.charts.setOption(option);
      }
    },
  },
};
</script>

<style>
</style>