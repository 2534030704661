<template>
  <div
    :style="!needSave && 'height:30px;padding:2px 24px'"
    class="room-body-header d-f ai-c jc-sb"
  >
    <div class="c-p" @click="$router.push('/clock')">
      &lt;{{ "返回上一页" }}
    </div>
    <div>
      <el-button v-if="needSave" type="primary" @click="$router.push('/clock')"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("housemaster");
export default {
  name: "room-body-header",
  components: {},
  props: {
    needSave: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["searchTable", "batAdd", "saveRoomInfo"]),
  },
  watch: {},
  computed: {
    ...mapState(["sectionItem"]),
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 24px;

  background: rgba(245, 245, 245, 0.4);
}
.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }
  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>